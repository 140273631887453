import React from "react";

import "./styles.css";

import picture from "../../assets/aindrilla.png";

const tags = ["Agile Product Manager", "Multifaceted MBA", "Yoga Instructor"];

const RightArrow = () => (
  <svg
    id="SVGDoc"
    width="18"
    height="12"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 18 12"
  >
    <defs></defs>
    <desc>Generated with Avocode.</desc>
    <g>
      <g>
        <title>tail-right</title>
        <g>
          <title>Path</title>
          <path
            d="M1,5.5h16"
            fillOpacity="0"
            fill="#ffffff"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeOpacity="1"
            stroke="#ffffff"
            strokeMiterlimit="20"
            strokeWidth="2"
          ></path>
        </g>
        <g>
          <title>Path</title>
          <path
            d="M12,1v0l5,5v0l-5,5v0"
            fillOpacity="0"
            fill="#ffffff"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeOpacity="1"
            stroke="#ffffff"
            strokeMiterlimit="20"
            strokeWidth="2"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

const ResumeDownloadCTA = () => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://assets.aindrilla.com/AindrillaDasResume.pdf"
    >
      <button>
        View Resume <RightArrow />
      </button>
    </a>
  );
};

export const Hero = () => {
  return (
    <section className="hero">
      <div className="introduction">
        <p>{tags.join(",  ")}</p>
        <h2>I drive product success with passion.</h2>
        <ResumeDownloadCTA />
      </div>
      <div className="profile">
        <img src={picture} alt="" />
      </div>
    </section>
  );
};
