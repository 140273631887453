import React from "react";

import "./styles.css";

const socials = [
  {
    text: "email",
    href: "mailto:contact@aindrilla.com",
  },
  {
    text: "linkedin",
    href: "https://www.linkedin.com/in/aindrilla",
  },
  {
    text: "facebook",
    href: "https://www.facebook.com/aindrillla",
  },
  {
    text: "instagram",
    href: "https://www.instagram.com/aindrilla_",
  },
  {
    text: "montro",
    href: "https://montro.life",
  },
];

export const Navbar = () => {
  return (
    <header>
      <nav>
        <div className="logo">
          <a href="/">
            <h4>aindrilla.</h4>
          </a>
        </div>
        <ul className="social-links">
          {socials.map((social) => (
            <li key={social.text} className="cv-cta">
              <a
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                className="smooth-transition"
              >
                {social.text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};
