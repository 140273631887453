import React from "react";
import { Navbar, Hero, Footer } from "./components";

import "./App.css";

const App = () => (
  <React.Fragment>
    <Navbar />
    <main>
      <Hero />
    </main>
    <Footer />
  </React.Fragment>
);

export default App;
